import {IError} from '../models/survey';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (title: string, e:any, overrideMessages?: string): IError => {
    if (e?.response?.data) {
        return {
            title,
            status: e.response.data.code,
            message: e.response.data.message,
        };
    } else if (e.response && !e?.response?.data.code && e.response.status && e.response.status < 500) {
        return {
            title,
            status: e.response.status,
            message: e.response.data.message,
        };
    } else {
        return {
            title,
            status: 'error',
        };
    }
};

export default errorHandler;